@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.characteristic {
  display: flex;
  height: 100%;
  
  .sidebar {
    height: 100%;
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include sidebarContainerTitle();
  }

  .container {
    position: relative;
    flex: 1;
    width: 100%;
    min-height: calc(100% - $header-height);
    overflow-y: auto;
    margin-top: $header-height;
    scroll-behavior: smooth;

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .content {
      @include content();
      will-change: opacity transform;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: $margin-lg;
      margin-bottom: $margin-lg;

      &.fillHeight {
        height: calc(100% - $header-height);
      }

    }

  }

}
