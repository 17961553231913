@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.countryRevelance {

  .containerField {
    @include containerField;
  }

  .country {
    display: flex;
    margin-bottom: $margin-lg;

    label {
      font-size: $h4;
      margin-bottom: $margin-xs;
    } 

    .containerFlag {
      margin-top: 40px;
      margin-right: $margin-lg;
      .flag {
        border-radius: 10px;
        overflow: hidden;
        width: 98px;
        height: 70px;
        img {
          width: 98px;
          height: 70px;
        }
      }
    }

    .container {
      flex: 1;


    .containerField {
      display: flex;
      width: 100%;
      margin-bottom: $margin-sm;
      border-bottom: solid 1px $grey;
  
      input {
        width: 100%;
        border: none;
        padding-left: 0;
        font-family: 'CenturyGothic';
      }
      >div:first-child {
        flex: 1;
      }
      
      .remove {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 40px;
        font-size: 20px;
        border:none;
        cursor: pointer;
      }
    }

    .append {
      background-color: transparent;
      color: $black;
      padding-left: 0;
      border:none;
      span {
        position: relative;
        top: -4px;
      }
      .icon {
        display: inline-block;
        background-color: $black;
        border-radius: 3px;
        margin-left: $margin-sm;
        font-size: $h4;
        color: $white;
        padding: 5px;
        border:none;
        line-height: 10px;
      }
    }
    }
  }

}