@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.quizPage {
  @include content-min();
  will-change: opacity transform;
  min-height: calc(100% - $header-height);
  margin-top: $margin-lg;
  margin-bottom: $margin-lg;

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: $margin-lg;
    ul {
      margin: 0;
      padding: 0;
      height: 23px;
      li {
        display: inline-block;
        position: relative;
        padding: 5px;
        &:after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: rgba($black, 0.3);
          transition: all 0.3s ease;
          cursor: pointer;
        }
        &.active:after {
          background-color: $text-color;
        }
      }
    }
  }

  .navigation+div{
    .characteristic {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: $margin-md;
      padding-bottom: $margin-md;
      border-bottom: solid 1px $grey;

      >div:first-child {
        flex: 0.4;
        padding: 0;

        h3 {
          margin: 0;
        }


        .content {
          display: flex;

          .characteristicImg {
            position: relative;
            width: 60px;
            height: 60px;
            min-width: 60px;
            min-height: 60px;
            margin-right: $margin-md;
            background-color: rgba($grey, 0.1);
            border-radius: $radius;
            overflow: hidden;
            background-position: center;
            background-size: cover;
          }
        }

      }
  
      .notations {
        flex: 0.6;
        padding: 0 $margin-lg;
        .notation {
          &:first-child {
            margin-bottom: $margin-sm;
          }
          label {
            display: inline-block;
            width: 80px;
            font-family: 'CenturyGothic-Bold';
            font-size: $regular;
            text-transform: uppercase;
          }
          .note {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 46px;
            height: 44px;
            border: solid 1px $text-color;
            border-radius: 4px;
            color: $text-color;
            transition: all 0.3s ease;
            cursor: pointer;

            &.selected {
              background-color: $text-color;
              p {
                color: $white;
              }
            }

            &:not(:last-child) {
              margin-right: $margin-sm;
            }
            p {
              margin: 0;
            }
          }
        }
      }
  
    }

  }

  .containerButton {
    text-align: right;
    button:last-child {
      margin-left: $margin-md;
    }

    button {
      &.disabled {
        opacity: 0.5;
      }
    }

    button:first-child {
      color: $text-color;
      background-color: transparent;
      &.disabled {
        opacity: 0.5;
      }
    }

  }

}
