@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.sector {
  display: flex;
  height: 100%;

  p {
    margin: 0 0 $margin-lg 0;
  }

  .sidebar {
    height: 100%;
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include sidebarContainerTitle();
  }

  .container {
    position: relative;
    flex: 1;
    width: 100%;
    overflow-y: auto;
    margin-top: $header-height;
    scroll-behavior: smooth;
    
    .content {
      @include content();
      will-change: opacity transform;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: calc(100% - $header-height);
      margin-top: $margin-lg;
      margin-bottom: $margin-lg;
    }

  }

  h5 {
    position: relative;
    padding-left: 17px;
    padding-bottom: $margin-xs;
    border-bottom: solid 1px rgba(58, 58, 58, 0.19);
    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 9px;
      height: 9px;
      transform: rotate(45deg);
      background-color: $black;
    }

    // &:after {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   width: 100%;
    //   height: 1px;
    //   transform: rotate(45deg);
    //   background-color: $black;
    // }

  }

}