@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.dataviz {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-top: $header-height;

  .container {
    position: relative;
    max-width: 500px;

    .close {
      position: absolute;
      top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      left: -40px;
      overflow: hidden;

      >div {
        width: 100%;
        height: 100%;
        will-change: opacity transform;
      }

      >div:after,
      >div:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        background-color: $black;
        height: 2px;
        width: 30px;
        border: 1px;
      }

      >div:before {
        transform: translate(-1px) rotate(45deg);
      }
      >div:after {
        transform: translate(-1px) rotate(-45deg);
      }
      cursor: pointer;
    }

    a {
      margin-bottom: $margin-md;
      button {
        will-change: opacity transform;
      }
    }

    .image {
      position: relative;
      max-height: 240px;
      width: 100%;
      overflow: hidden;
      border-radius: $radius;
      margin-bottom: $margin-md;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      img {
        width: 100%;
        height: 100%;
        max-height: 240px;
        object-fit: cover;
        will-change: opacity transform;
      }
    }
    
    .selectRank {
      display: flex;
      align-items: flex-end;
      margin-bottom: $margin-sm;

      ul {
        display: flex;
        align-items: flex-end;
        padding: 0;
        margin: 0 $margin-sm 0 0;
        will-change: opacity transform;
        li {
          list-style-type: none;
          margin: 0 $margin-xs;
          .rank {
            position: relative;
            border-radius: 50%;
            background-color: $grey;
  
            &.selected {
              background-color: $black;
            }
  
            &.rank1 {
              width: 11px;
              height: 11px;
            }
            &.rank2 {
              width: 23px;
              height: 23px;
            }
            &.rank3 {
              width: 32px;
              height: 32px;
            }
          }
        }
      }

      label {
        position: relative;
        top: 2px;
        font-family: 'CenturyGothic-Bold';
        margin: 0 0 0 $margin-sm;
        width: 65px;
      }
    }

  }

}