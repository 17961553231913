@import './_mixins.scss';
@import './_variables.scss';

@font-face {
	font-family: "CenturyGothic-Bold";
	src: url("../assets/fonts/gothicb.ttf") format("truetype");
}

@font-face {
	font-family: "CenturyGothic";
	src: url("../assets/fonts/Century-Gothic.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: sans-serif;
}

html, body, #root, .App {
  min-height: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  @include h1();
  margin: 0 0 $margin-sm 0;
}

h3 {
  @include h4();
  margin: 0 0 $margin-md 0;
}

h4 {
  @include h4();
  margin: 0 0 $margin-sm 0;
}

h5 {
  @include h5();
  margin: 0 0 $margin-md 0;
}

label,
p {
  font-size: $regular;
  font-family: 'CenturyGothic';
  color: $text-color;
  margin: 0 0 $margin-sm 0;
}

a, li {
  font-size: $regular;
  font-family: 'CenturyGothic';
  color: $text-color;
}

button {
  padding: 10px $margin-sm;
  border-radius: $radius;
  min-height: 37px;
  border: 0;
  background-color: transparent;

  outline: none;
  cursor: pointer;
  font-family: 'CenturyGothic-Bold';
  font-size: $small;
  color: $white;
  background-color: $black;
  border: solid 1px $black;
  transition: background-color 0.4s ease-in-out;

  &.light {
    color: $text-color;
    background-color: transparent;
  }

  &.disabled {
    pointer-events: none;
    border-color: $text-color-light;
    background-color: $text-color-light;
  }

}