@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.examples {
  .containerField {
    @include containerField;
  }

  .append {
    background-color: transparent;
    color: $black;
    padding-left: 0;
    border:none;
    margin-bottom: $margin-sm;
    span {
      position: relative;
      top: -4px;
    }
    .icon {
      display: inline-block;
      background-color: $black;
      border-radius: 3px;
      margin-left: $margin-sm;
      font-size: $h4;
      color: $white;
      padding: 5px;
      border:none;
      line-height: 10px;
    }
  }

  .gridExamples {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;

    .thumbnail {
      position: relative;
      width: 100%; // 401
      overflow: hidden;

      &:not(.hidden) {
        padding-top: 55%;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        object-fit: cover;
        height: 100%;
        width: 100%;
        max-height: inherit;
        transform: translate(-50%, -50%);
      }
    }

    .title input {
      font-size: $medium;
    }

    .description {
      margin-bottom: $margin-sm;
    }

    .url {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg {
        position: relative;
        top: 1px;
        color: $text-color-light;
      }
      

      span {
        color: $text-color-light;
        font-family: 'CenturyGothic-Bold';
        margin-left: $margin-sm;
        +div {
          flex: 1;
          margin-left: $margin-xs;
        }
      }

    }

    .remove {
      text-align: right;
      p {
        margin: 0;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}