@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 220px;
  a {
    position: relative;
    font-family: 'CenturyGothic-Bold';
    font-size: $small;
    color: $text-color-light;
    text-transform: uppercase;
    // height: 50px;
    // line-height: 50px;
    padding: 18px $margin-md 18px $margin-xl;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: $black;
      transform-origin: center left;
      transform: scaleX(0);
      transition: all 0.3s;
      will-change: transform;
    }
    &.active {
      color: $text-color;
      &:after {
        transform: scaleX(1);
      }
    }
  }
}