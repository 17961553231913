@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.header {
  position: fixed;
  width: 100%;
  z-index: 3;
  padding: 0 $margin-md;

  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $header-height;
    overflow: hidden;
    border-bottom: solid 1px $black;

    .logo {
      position: absolute;
      left: 0;
      height: 34px;
    }

    ul {
      li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        text-align: center;
        width: 130px;
        height: $header-height;
        a {
          display: inline-block;
          position: relative;
          height: 100%;
          line-height: 75px;
          width: 100%;
          font-size: $small;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 5px;
            background-color: $black;
            border-radius: 5px 5px 0 0;
            transform: translateY(100%);
            transition: all .3s ease-in-out;
          }
          &.active:after {
            transform: translateY(0%);
          }
        }
      }
    }
  }
}