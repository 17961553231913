@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.sector {
  @include remove-highlight();
  @include remove-tap-highlight();

  .title {
    @include content();
    margin-top: $margin-lg;
    margin-bottom: $margin-lg;
    display: flex;
    justify-content: space-between;
    align-items: center;

    >div:first-child {
      margin-right: auto;
    }

    cursor: pointer;
    h1 {
      margin-bottom: 0;
    }

    p {
      margin: 0;
      font-family: 'CenturyGothic-Bold';
      font-size: $small;
      text-transform: uppercase;
    }

  }

  ul {
    display: flex;
    overflow-x: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: $margin-lg;
    &::-webkit-scrollbar {
      display: none;
    }
    // margin: 0 calc($margin-lg * -1);
    li {
      list-style: none;
      cursor: pointer;

      &:first-child {
        margin-left: $margin-md;

        @media screen and (min-width: $sm-screen) {
          margin-left: $margin-lg;
        }
        
        @media screen and (min-width: 1440px) {
          margin-left: calc((100% - (1440px - $margin-xl)) / 2);
        }
      }

      &:not(:last-child) {
        margin-right: $margin-md;
      }
      .containerImg {
        width: 245px;
        height: 248px;
        border-radius: 4px;
        margin-bottom: $margin-md;
        overflow: hidden;

        .img {
          width: 100%;
          height: 100%;
          transform: translate(0, 100%);
          background-position: center;
          background-size: cover;
        }
      }

      .characteristicTitle {
        h5 {
          font-family: 'CenturyGothic-Bold';
          font-size: $medium
        }
      }

    }
  }

  .separator {
    @include content();
    &:after {
      content: '';
      display: block;
      border-top: solid 1px $black;
    }
  }
}