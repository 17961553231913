@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.introduction {

  .cover {
    overflow: hidden;
    border-radius: $radius;
    font-size: 0;
    margin-bottom: $margin-md;
    height: 200px;
    img {
      width: 100%;
      max-height: 200px;
      object-fit: cover;
    }
  }

  .containerField {
    @include containerField;
  }

  .distribution {
    display: flex;
    align-items: flex-end;
    padding-bottom: $margin-lg;
    border-bottom: solid 1px $black;
    margin-bottom: $margin-lg;

    .selectRank {
      display: flex;
      align-items: flex-end;
      padding: $margin-sm $margin-lg 0 0;
      border-right: solid 1px $black;

      ul {
        display: flex;
        align-items: flex-end;
        padding: 0;
        margin: 0 $margin-sm 0 0;
        will-change: opacity transform;
        li {
          list-style-type: none;
          margin: 0 $margin-xs;
          .rank {
            position: relative;
            border-radius: 50%;
            background-color: $grey;
  
            &.selected {
              background-color: $black;
            }
  
            &.rank1 {
              width: 16px;
              height: 16px;
            }
            &.rank2 {
              width: 33px;
              height: 33px;
            }
            &.rank3 {
              width: 64px;
              height: 64px;
            }
          }
        }
      }

      label {
        position: relative;
        top: 2px;
        font-family: 'CenturyGothic-Bold';
        margin: 0 $margin-md 0 $margin-sm;
      }
    }

    .percents {
      display: flex;
      padding-left: $margin-lg;
      >div:not(:last-child) {
        margin-right: $margin-lg;
      }

      .percent {
        width: 20px;
        max-height: 100%;
        min-height: 1px;
        border-radius: 5px;
        background-color: $grey;
      }

      .containerPercent {
        display: flex;
        align-items: flex-end;
        .location {
          text-align: right;
          margin-right: $margin-sm;
          p {
            font-family: 'CenturyGothic-Bold';
            margin: 0;
          }
          img {
            height: 18px !important;
            width: 27px !important;
          }
        }
      }



    }
  }

  .introduction {
    font-family: 'CenturyGothic-Bold';
    font-size: $h4;
    margin: 0 0 $margin-lg 0;
  }

  .twoColumns {
    display: flex;
    align-items: flex-start;

    .containerVideo {
      flex: 0.4;
      position: relative;
      width: 100%;
      // padding-top: 20%;
      border-radius: $radius;
      background-color: black;
      margin-bottom: $margin-lg;
      overflow: hidden;
      border-radius: $radius;
      font-size: 0px;
  
      // .play {
      //   position: absolute;
      //   z-index: 2;
      //   top: 50%;
      //   left: 50%;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   width: 105px;
      //   height: 105px;
      //   background-color: $black;
      //   border-radius: $radius;
      //   transform: translate(-50%, -50%);
      //   color: $white;
      //   font-size: 70px;
      // }
  
      video {
        width: 100%;
        z-index: 1;
      }

      +.socioCulturals {
        margin-left: $margin-lg;
      }

    }

    .socioCulturals {
      flex: 1;
      @include list();
    }
  }



  @include fromTo();

}
