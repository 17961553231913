$h1: 48px;
$h2: 38px;
$h4: 20px;
$medium: 16px;
$regular: 14px;
$small: 12px;

$primary-color: #000000;
$secondary-color: #000000;

$text-color: #000000;
$text-color-light: #636363;

$black: #000000;
$grey: #A0ACBD;
$white: #FFFFFF;

$success: #5BC284;
$warn: #FA5C4F;

$md-screen: 1024px;
$sm-screen: 768px;
$xs-screen: 450px;

$margin-xl: 50px;
$margin-lg: 30px;
$margin-md: 20px;
$margin-sm: 10px;
$margin-xs: 5px;

$radius: 3px;

$header-height: 77px;