@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.examples {
  .containerField {
    @include containerField;
  }

  .example {
    position: relative;
    border: solid 1px $black;
    border-radius: $radius;
    overflow: hidden;

    .containerImage {
      position: relative;
      width: 100%; // 401
      padding-top: 55%;
      border-radius: $radius;
      overflow: hidden;
      font-size: 0;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        object-fit: cover;
        height: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .url {
      display: flex;
      margin-bottom: $margin-md;
      span {
        text-decoration: underline;
      }
      svg {
        position: relative;
        top: 3px;
        margin-right: $margin-sm;
        color: $text-color-light;
      }
    }

    .text {
      position: relative;
      padding: 0 70px 0 $margin-md;
      .title {
        font-family: 'CenturyGothic-Bold';
        font-size: $medium;
        margin: $margin-md 0 $margin-md 0;
        +div>p {
          margin-top: calc($margin-sm * -1);
        }
      }

      p {
        margin: 0 0 $margin-md 0;
      }
    }

    .text .toggle {
      position: absolute;
      top: -5px;
      right: $margin-md;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      overflow: hidden;
      cursor: pointer;

      >div {
        width: 100%;
        height: 100%;
        will-change: opacity transform;
      }

      >div:after,
      >div:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        background-color: $black;
        height: 1px;
        width: 29px;
        border: 1px;
        transition: all 0.3s ease-in-out;
      }

      >div:before {
        transform: translate(-1px) rotate(90deg);
      }


      &.close {
        >div:before {
          transform: translate(-1px);
        }
      }

      >div:after {
        transform: translate(-1px);
      }
      cursor: pointer;
    }

  }

}
