@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.chart {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  .containerDataFrame.small svg {
    width: 700px;
    height: 557px;
  }

  .containerNodes {
    position: absolute;
    .node {
      position: absolute;
      border-radius: 50%;
      cursor: pointer;
      will-change: opacity transform;
      &:hover .positionDetails {
        visibility: visible;
      }
      label {
        position: absolute;
        left: 50%;
        display: inline-block;
        font-size: $small;
        width: 100px;
        text-align: center;
        transform: translate(-50%, -100%);
      }

      .positionDetails {
        position: absolute;
        z-index: 10;
        left: 20px;
        top: 20px;
        display: inline-block;
        background-color: $white;
        border-radius: $radius;
        padding: $margin-sm;
        pointer-events: none;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
        visibility: hidden;
        p {
          font-family: 'CenturyGothic-Bold';
          font-size: $small;
          white-space: nowrap;
          margin: 0 0 $margin-xs 0;
        }
      }

    }
  }

}