@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.quizStart {
  @include content();
  will-change: opacity transform;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - $header-height);
  margin-top: $margin-lg;
  margin-bottom: $margin-lg;
  >div:first-child {
    flex: 0.4;
    padding: 0 0 0 calc($margin-xl * 2);
    h1 {
      margin-bottom: $margin-lg;
    }
    p {
      margin-bottom: $margin-lg;
    }
  }
  >div:nth-child(2) {
    flex: 0.6;
    padding: 0 $margin-lg;
    img {
      display: block;
      margin-left: auto ;
    }
  }
}
