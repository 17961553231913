@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.moodboard {
  .containerField {
    @include containerField;
  }

  .moodboard {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: $margin-md;
    margin: 0 0 $margin-xl 0;
    max-height: 500px;

    &.grid1 {
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    &.grid2 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    &.grid3 {
      >div:first-child { grid-area: 1 / 1 / 2 / 3; }
      >div:nth-child(2) { grid-area: 1 / 3 / 2 / 7; }
      >div:nth-child(3) { grid-area: 2 / 1 / 3 / 7; }
    }

    &.grid4 {
      >div:first-child { grid-area: 1 / 1 / 2 / 3; }
      >div:nth-child(2) { grid-area: 1 / 3 / 2 / 5; }
      >div:nth-child(3) { grid-area: 1 / 5 / 2 / 7; }
      >div:nth-child(4) { grid-area: 2 / 1 / 3 / 7; }
    }

    &.grid5 {
      >div:first-child { grid-area: 1 / 1 / 2 / 3; }
      >div:nth-child(2) { grid-area: 1 / 3 / 2 / 5; }
      >div:nth-child(3) { grid-area: 1 / 5 / 2 / 7; }
      >div:nth-child(4) { grid-area: 2 / 1 / 3 / 4; }
      >div:nth-child(5) { grid-area: 2 / 4 / 3 / 7; }
    }

    &.grid6 {
      >div:first-child { grid-area: 1 / 1 / 2 / 3; }
      >div:nth-child(2) { grid-area: 1 / 3 / 2 / 5; }
      >div:nth-child(3) { grid-area: 1 / 5 / 2 / 7; }
      >div:nth-child(4) { grid-area: 2 / 1 / 3 / 3; }
      >div:nth-child(5) { grid-area: 2 / 3 / 3 / 5; }
      >div:nth-child(6) { grid-area: 2 / 5 / 3 / 7; }
    }

    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $radius;
      overflow: hidden;
      height: 100%;

      a {
        position: relative;
        display: inline-block;
        height: 100%;
        width: 100%;

        .icon {
          position: absolute;
          right: 10px;
          bottom: 10px;
          color: $white;
          background-color: $black;
          padding: 5px 6.5px;
          border-radius: 50%;
          svg {
            position: relative;
            top: 1px;
          }
        }

      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        font-size: 0;
      }
    }
  }

  .keyTakeOuts {
    @include list();
  }

  .consumerTypologies {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;
    margin-top: $margin-lg;
    .consumerTypology {
      position: relative;
      margin-top: $margin-sm;
      border: solid 1px $black;
      border-radius: $radius;
      label {
        position: absolute;
        z-index: 2;
        left: 50%;
        display: block;
        font-family: 'CenturyGothic-Bold';
        text-transform: uppercase;
        background-color: $black;
        color: $white;
        width: 120px;
        margin: 0 auto 0 auto;
        text-align: center;
        padding: $margin-sm;
        border-radius: $radius;
        transform: translate(-50%, -50%);
      }

      .containerImage {
        position: relative;
        width: 100%;
        padding-top: 33%;
        overflow: hidden;
        img {
          position: absolute;
          top:0;
          width: 100%;
        }
      }

      .title {
        margin: $margin-md 0 $margin-xs 0;
        font-size: $medium;
      }

      p {
        font-family: 'CenturyGothic-Bold';
        text-transform: uppercase;
        padding: 0 $margin-md;
        margin: 0 0 $margin-md 0;
        span {
          font-family: 'CenturyGothic';
        }
      }

    } 
  }

}
