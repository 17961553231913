@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.luxuryCodes {
  height: 100%;
  overflow-y: scroll;
  padding-top: $header-height;

  .content .banner {
    @include content();

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $margin-md 0;
    margin: $margin-lg auto;

    .luxury {
      h3 {
        font-size: 32px;
        margin-bottom: 3px;
        + p {
          text-transform: uppercase;
          font-size: 9px;
          letter-spacing: 0.55ch;
        }
      }
    }

  }

  .content .sectors {
    overflow: hidden;

    .separator {
      @include content();
      &:after {
        content: '';
        display: block;
        border-top: solid 1px $black;
      }
    }
  }

}