@import "../../styles/_variables.scss";

.radar {
  .tooltip {
    display: inline-block;
    // margin: 0 auto;
    z-index: 2;
    background-color: $white;
    text-align: center;
    padding: 5px;
    border: solid 1px $black;
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
    }
    
    p {
      font-size: $small;
      font-family: 'CenturyGothic-Bold';
      text-transform: uppercase;
      margin: 0 !important;
      max-width: 100px;
    }

  }

  circle {
    cursor: pointer;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }

  foreignObject,
  polygon {
    pointer-events: none;
  }

}
