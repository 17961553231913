@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.introduction {
  .containerField {
    @include containerField;
    
    &.picture img {
      object-fit: cover;
    }

  }

  .fromTo {
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
      flex: 1;
    }

    input {
      text-transform: uppercase;
      border-color: $grey;
    }

    .arrow {
      position: relative;
      min-width: calc(100% - 350px);
      height: 1px;
      background-color: $black;
      margin: 0 $margin-md;
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0.5px;
        right: 0;
        width: 5px;
        height: 5px;
        border-top: solid 1px $black;
        border-right: solid 1px $black;
        transform-origin: 100% 0;
        transform: translateX(1px) rotate(45deg);
      }
    }
  }
  
}
