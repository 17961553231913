@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.countryRevelance {
  min-height: 75vh;
  .containerField {
    @include containerField;
  }

  .country {
    display: flex;
    margin-bottom: $margin-lg;

    h4 {
      text-transform: uppercase;
      margin-bottom: $margin-md;
    } 

    .containerFlag {
      margin-top: 45px;
      margin-right: $margin-lg;
      .flag {
        border-radius: 10px;
        overflow: hidden;
        width: 98px;
        height: 70px;
        img {
          width: 98px;
          height: 70px;
        }
      }
    }

    .container {
      flex: 1;

      .containerField {
        display: flex;
        width: 100%;
        max-width: 800px;
        margin-bottom: $margin-sm;
        border-bottom: solid 1px $grey;
    
        p {
          margin: 0 0 $margin-sm 0;
        }
        >div:first-child {
          flex: 1;
        }
      }
    }
  }

}
