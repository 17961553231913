@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.quizVision {
  @include content();
  will-change: opacity transform;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - $header-height);
  margin-top: $margin-lg;
  margin-bottom: $margin-lg;

  >div:first-child {
    flex: 0.53;
    padding: 0 0 0 calc($margin-xl * 2);

    .radar {
      display: block;
      margin: 0 auto;
      width: 560px;
      margin: 0 auto;
    }

  }
  .characteristics {
    flex: 0.47;
    padding: 0 $margin-lg;
    min-height: 578px;

    >div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-lg;
      max-width: 400px;

      button {
        padding: $margin-xs;
      }

    }

    .characteristic {
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: $margin-md;
      }
      .containerImg {
        position: relative;
        width: 145px;
        height: 93px;
        margin-right: $margin-md;
        background-color: rgba($grey, 0.1);
        border-radius: $radius;
        overflow: hidden;
        .img {
          width: 100%;
          height: 100%;
          >div {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
          }
        }
      }

      .containerImg + div {
        width: 100%;
      }

      h3 {
        margin-bottom: $margin-xs;
        font-size: 18px;
      }

      .containerBar {
        max-width: 260px;
        width: 100%;
        margin-bottom: $margin-sm;
        .bar {
          height: 17px;
          border-radius: 3px;
        }
      }

      button {
        padding: 10px;
      }

    }

  }

  .toggleChart {
    text-align: center;
    margin: $margin-md 0 0 0 ;
    li {
      padding: $margin-xs $margin-md !important;
      &:after {
        width: 100px !important;
      }
    }
  }
  
  .toggleChart,
  .toggleVision {
    padding: 0;

    li {
      position: relative;
      display: inline-block;
      list-style-type: none;
      font-size: $regular;
      font-family: 'CenturyGothic-Bold';
      text-transform: uppercase;
      margin: 0 $margin-sm;
      padding: $margin-xs 0;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0%) scaleX(0);
        display: block;
        width: 70px;
        height: 2px;
        background-color: $black;
        transition: transform 0.3s ease-in-out;
      }
      &.active:after {
        transform: translate(-50%, 0%) scaleX(1);
      }
    }
  }

  button.loading {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: $black;
      top: 12px;
      left: -20px;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-name: rotate;
    }


    @keyframes rotate {
      0% {
        transform: rotateX(0) rotateY(0);
        }
      50% {
        transform: rotateX(-180deg) rotateY(0);
      }
      100% {
        transform: rotateX(-180deg) rotateY(-180deg);
      }
  }

  }

}
