@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.view {
  display: flex;
  align-items: flex-end;
  height: calc(100vh - 137px);
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;

  .containerField {
    @include containerField;
  }


  .container {
    display: flex;
  }

  .container>div {
    position: relative;
    padding: $margin-lg;
    width: 50%;
    border-top: solid 1px rgba(255, 255, 255, 0.4);
    background-color: rgba($black, 0.3);

    &:first-child:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      display: block;
      height: calc(100% - $margin-lg * 2);
      width: 1px;
      background-color: rgba(255, 255, 255, 0.4);
      transform: translateY(-50%);
    }

    h5,
    p {
      font-family: 'CenturyGothic-Bold';
      color: $white;
    }
    
    h5 {
      font-size: $regular;
    }

    p {
      font-size: $medium;
      &.source {
        font-family: 'CenturyGothic';
        font-size: $small;
      }
    }

  }

}
