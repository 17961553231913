@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.view {
  .containerField {
    @include containerField;
  }
  
  .gridTwo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;

    textarea {
      font-size: $medium;
    }
  }
}