@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.moodboard {

  .containerField {
    @include containerField;
  }

  .signalsCommunicated {
    margin-bottom: $margin-lg;

    .containerSearch {
      display: flex;
      >div:first-child {
        width: 300px;
        margin-right: $margin-sm;
      }
    }

    .selectedOptions {
      display: flex;
      flex-wrap: wrap;

      .containerField {
        display: flex;
        align-items: center;
        margin-bottom: $margin-sm;
        background-color: var(--tonic);
        padding: 0 $margin-sm;
        border-radius: $radius;
        &:not(:last-child) {
          margin-right: $margin-sm;
        }
    
        p {
          width: 100%;
          border: none;
          padding-left: 0;
          margin: 0;
          color: $white;
          font-size: $small;
          font-family: 'CenturyGothic';
        }
        >div:first-child {
          flex: 1;
        }
        
        .remove {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 40px;
          font-size: 20px;
          border:none;
          margin-left: $margin-xs;
          color: $white;
          transform: rotate(-45deg);
          cursor: pointer;
        }
      }
    }

    .append {
      border:none;
      font-size: 20px;
      height: 40px;
      width: 40px;
    }
  }

  .semioticSocials {
    margin-bottom: $margin-lg;
    label {
      position: relative;
      padding-left: 24px;
      img {
        position: absolute;
        top: -5px;
        left: 0;
      }
    }

    .containerField {
      display: flex;
      width: 100%;
      margin-bottom: $margin-sm;
      border-bottom: solid 1px $grey;
  
      input {
        width: 100%;
        border: none;
        padding-left: 0;
        font-family: 'CenturyGothic';
      }
      >div:first-child {
        flex: 1;
      }
      
      .remove {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 40px;
        font-size: 20px;
        border:none;
        cursor: pointer;
      }
    }

    .append {
      background-color: transparent;
      color: $black;
      padding-left: 0;
      border:none;
      span {
        position: relative;
        top: -4px;
      }
      .icon {
        display: inline-block;
        background-color: $black;
        border-radius: 3px;
        margin-left: $margin-sm;
        font-size: $h4;
        color: $white;
        padding: 5px;
        border:none;
        line-height: 10px;
      }
    }
  }

}