@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.links {
  position: relative;
  height: calc(100vh - 137px);
  .container {
    display: inline-block;
  }

  .links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: flex-start;
    grid-gap: $margin-md;
  }

  .linkedCharacteristic {
    width: 245px;

    .containerImage {
      width: 245px;
      height: 248px;
      overflow: hidden;
      border-radius: $radius;
      font-size: 0;
      margin-bottom: $margin-sm;
      background-color: lighten($grey, 27%);
      img {
        width: 245px;
        height: 248px;
        object-fit: cover;
      }
    }

    p {
      font-family: 'CenturyGothic-Bold';
      font-size: $medium;
    }
  }

}
