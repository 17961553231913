@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.introduction {
  .containerField {
    @include containerField;
  }

  .containerPicture {
    width: 100%;
    margin-bottom: $margin-lg;
    border-radius: $radius;
    overflow: hidden;
    font-size: 0;
    max-height: 500px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  h3 {
    display: inline-block;
    margin: 0 0 $margin-lg 0;
    border-radius: 3px;
    border: 1px solid;
    border-color: var(--tonic);
    padding: $margin-sm;
  }

  @include fromTo();

}
