@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.description {
  .containerField {
    @include containerField;
  }

  .details {
    display: flex;
    .sectorAndDesc {
      width: 40%;
      min-width: 400px;
      max-width: 600px;
      margin-right: $margin-md;
    }
    .picture {
      flex: 1;
    }
  }

}