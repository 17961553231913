@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.edit {
  display: flex;
  height: 100%;
  
  label {
    display: block;
    text-transform: uppercase;
    font-family: 'CenturyGothic-Bold';
  }

  .sidebar {
    height: 100%;
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include sidebarContainerTitle();

  }
  .container {
    flex: 1;
    overflow-y: auto;
    padding-top: $header-height;
    
    .form {
      @include content();
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: calc(100% - $header-height);
      margin-top: $margin-lg;
      margin-bottom: $margin-lg;
    }

  }

}