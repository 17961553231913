@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.introduction {
  
  .containerField {
    @include containerField;

    &.cover {
      img {
        object-fit: cover;
      }
    }

  }

  .distribution {
    display: flex;
    align-items: flex-end;
    padding-bottom: $margin-lg;
    border-bottom: solid 1px $black;
    margin-bottom: $margin-lg;

    .selectRank {
      display: flex;
      align-items: flex-end;
      padding-right: $margin-lg;
      border-right: solid 1px $black;

      label {
        font-family: 'CenturyGothic-Bold';
        margin: 0 $margin-sm 0 0;
        width: 65px;
      }
      ul {
        display: flex;
        align-items: flex-end;
        padding: 0;
        margin: 0;
        li {
          list-style-type: none;
          margin: 0 $margin-xs;
          cursor: pointer;
          .rank {
            position: relative;
            border-radius: 50%;
            border: dashed 1px $text-color-light;
            background-color: transparent;
            // box-shadow: inset 0 0 0 3px $white;
  
            &:after {
              content: '';
              display: block;
              position: absolute;
              width: calc(100% - 8px);
              height: calc(100% - 8px);
              border-radius: 50%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: $grey;
              transition: all .3s ease-in-out;
            }
  
            &.selected:after {
              background-color: $black;
            }
  
            &.rank1 {
              width: 16px;
              height: 16px;
            }
            &.rank2 {
              width: 33px;
              height: 33px;
            }
            &.rank3 {
              width: 64px;
              height: 64px;
            }
          }
        }
      }
    }
  
    .percents {
      display: flex;
      padding-left: $margin-lg;
      >div:not(:last-child) {
        margin-right: $margin-sm;
      }

      >div input {
        width: 70px;
        border-color: $grey;
      }

      .containerInput {
        display: flex;
        .location {
          text-align: right;
          margin-right: $margin-sm;
          p {
            font-family: 'CenturyGothic-Bold';
            margin: 0;
          }
          img {
            height: 20px !important;
            width: 30px !important;
          }
        }
      }



    }
  }

  .introduction {
    textarea {
      font-size: $h4;
    }
  }

  .twoColumns {
    display: flex;
    align-items: flex-start;

    .video {
      flex: 0.4;
      +.socioCulturals {
        margin-left: $margin-lg;
      }
      
    }

    .socioCulturals {
      flex: 1;
      margin-bottom: $margin-lg;
  
      .containerField {
        display: flex;
        width: 100%;
        margin-bottom: $margin-sm;
        border-bottom: solid 1px $grey;
    
        input {
          width: 100%;
          border: none;
          padding-left: 0;
          font-family: 'CenturyGothic';
        }
        >div:first-child {
          flex: 1;
        }
        
        .remove {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 40px;
          font-size: 20px;
          border:none;
          cursor: pointer;
        }
      }
  
      .append {
        background-color: transparent;
        color: $black;
        padding-left: 0;
        border:none;
        span {
          position: relative;
          top: -4px;
        }
        .icon {
          display: inline-block;
          background-color: $black;
          border-radius: 3px;
          margin-left: $margin-sm;
          font-size: $h4;
          color: $white;
          padding: 5px;
          border:none;
          line-height: 10px;
        }
      }
    }

  }

  .fromTo {
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
      flex: 1;
    }

    input {
      text-transform: uppercase;
      border-color: $grey;
    }

    .arrow {
      position: relative;
      min-width: calc(100% - 350px);
      height: 1px;
      background-color: $black;
      margin: 0 $margin-md;
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0.5px;
        right: 0;
        width: 5px;
        height: 5px;
        border-top: solid 1px $black;
        border-right: solid 1px $black;
        transform-origin: 100% 0;
        transform: translateX(1px) rotate(45deg);
      }
    }
  }

}