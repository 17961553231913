@import "../../styles/_variables.scss";

.layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  height: 100%;
  // overflow-y: scroll;

  // scrollbar-width: none;  /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  .containerGradients {
    position: fixed;
    top: 0;
    z-index: 0;
    height: 100%;
    width: 100%;

    .gradients {
      position: absolute;
      height: 100%;
      width: 100%;
      animation: gradient 20s ease infinite;
      will-change: opacity, transform;
      @keyframes gradient {
        0% {
          transform: rotate(0deg) translate(-5%, -10%);
        }
        30% {
          transform: translate(20%, 10%);
        }
        60% {
          transform: translate(40%, 10%);
        }
        100% {
          transform: rotate(360deg) translate(-5%, -10%);
        }
      }

      
      .tonic {
        position: absolute;
        width: 35%;
        padding-top: 27%;
        background-color: var(--tonic);
        // background: radial-gradient(var(--tonic), rgba(0, 0, 0, 0));
        transition: background-color 1.5s ease-in;
        filter: blur(300px);
        animation: tonicAnimation 30s ease infinite;
        will-change: opacity, transform;
        contain: layout;
        @keyframes tonicAnimation {
          0% {
            transform: translate(-5%, -10%);
          }
          30% {
            transform: translate(160%, 200%);
          }
          60% {
            transform: translate(250%, 10%);
          }
          100% {
            transform:  translate(-5%, -10%);
          }
        }
      }

      .tonic2 {
        position: absolute;
        left: 40%;
        top: -10%;
        width: 45%;
        padding-top: 25%;
        background-color: var(--tonic);
        // background: radial-gradient(var(--tonic), rgba(0, 0, 0, 0));
        transition: background-color 1.5s ease-in;
        filter: blur(250px);
        animation: tonic2Animation 25s ease infinite;
        will-change: opacity, transform;
        contain: layout;
        @keyframes tonic2Animation {
          0% {
            transform: translate(-5%, -10%);
          }
          30% {
            transform: translate(40%, 10%);
          }
          60% {
            transform: translate(-20%, 10%);
          }
          100% {
            transform: translate(-5%, -10%);
          }
        }
      }

      .grey {
        position: absolute;
        // left: 50%;
        top: 0%;
        padding-top: 25%;
        width: 25%;
        background-color: #A0ACBD;
        // background: radial-gradient(#A0ACBD, rgba(0, 0, 0, 0));
        filter: blur(250px);
        animation: greyAnimation 30s ease infinite;
        will-change: opacity, transform;
        contain: layout;
        @keyframes greyAnimation {
          0% {
            opacity: 0.7;
            transform: translate(300%, -10%);
          }
          25% {
            opacity: 0.4;
            transform: translate(0%, 100%);
          }
          50% {
            transform: translate(-5%, -10%);
          }
          75% {
            transform: translate(300%, 100%);
          }
          100% {
            opacity: 0.7;
            transform: translate(300%, -10%);
          }
        }
      }

      .grey2 {
        position: absolute;
        bottom: 0%;
        right: 0%;
        padding-top: 25%;
        width: 35%;
        background-color: #A0ACBD;
        // background: radial-gradient(#A0ACBD, rgba(0, 0, 0, 0));
        filter: blur(300px);
        animation: grey2Animation 25s ease infinite;
        will-change: opacity, transform;
        contain: layout;
        @keyframes grey2Animation {
          0% {
            opacity: 1;
            transform: translate(0%, 0%);
          }
          30% {
            opacity: 0.6;
            transform: translate(10%, 10%);
          }
          60% {
            opacity: 0.7;
            transform: translate(-10%, 10%);
          }
          100% {
            transform: translate(0%, 0%);
            opacity: 1;
          }
        }
      }

    }


    
  }

  .content {
    position: relative;
    z-index: 1;
    flex: 1;
    height: 100%;
  }
}